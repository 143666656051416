import {
  Autocomplete,
  Backdrop,
  Box,
  Card,
  Chip,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./edit_restaurant_styles";
import { useStoreActions, useStoreState } from "easy-peasy";
import Button1 from "../button_1/button_1";
import ImagePicker from "../image_picker/image_picker";
import AdminApiService from "../../api_service/admin_api_service";
import ModelHeader from "../model_header/model_header";
import { showAlert } from "../message_box/show_alert";

const restaurantLanguages = [
  {
    label: "English",
    value: "EN",
  },
  // {
  //   label: "Sinhala",
  //   value: "SI",
  // },
];

export default function EditRestaurant() {
  const classes = useStyles();
  const openRestaurantEditModal = useStoreState(
    (state) => state.openRestaurantEditModal
  );
  const itemToEdit = useStoreState((state) => state.restaurantToEdit);
  const setOpenRestaurantEditModal = useStoreActions(
    (actions) => actions.setOpenRestaurantEditModal
  );

  const [state, setState] = useState({
    logo_image: null,
    logo: itemToEdit?.logo || "",
    background: itemToEdit?.background || "",
    code: itemToEdit?.code || "",
    name: itemToEdit?.name || "",
    address: itemToEdit?.address || "",
    user_name: itemToEdit?.user_name || "",
    password: itemToEdit?.password || "",
    admin_password: itemToEdit?.admin_password || "",
    mobile_no: itemToEdit?.mobile_no || "",
    fixed_line: itemToEdit?.fixed_line || "",
    web_site_url: itemToEdit?.web_site_url || "",
    trip_advisor_url: itemToEdit?.trip_advisor_url || "",
    google_map_url: itemToEdit?.google_map_url || "",
    facebook_url: itemToEdit?.facebook_url || "",
    instagram_url: itemToEdit?.instagram_url || "",
    twitter_url: itemToEdit?.twitter_url || "",
    language: itemToEdit?.language || restaurantLanguages[0].value,
    allow_payments: itemToEdit?.allow_payments === false ? false : true,
    one_order_per_table:
      itemToEdit?.one_order_per_table === false ? false : true,
    print_receipt: itemToEdit?.print_receipt || false,
    currency: itemToEdit?.currency || "LKR",
    printer_model: itemToEdit?.printer_model || "",
    app_fee_percentage: itemToEdit?.app_fee_percentage || 5,
    bank_account_details: itemToEdit?.bank_account_details || "",
    self_ordering: itemToEdit?.self_ordering || false,
    print_kitchen_note: itemToEdit?.print_kitchen_note || false,
    kitchen_printer_model: itemToEdit?.kitchen_printer_model || "",
    transaction_methods: itemToEdit?.transaction_methods || [],
  });

  const changeState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const setInitialData = () => {
    setState({
      ...state,
      logo_image: null,
      logo: itemToEdit?.logo || "",
      background: itemToEdit?.background || "",
      code: itemToEdit?.code || "",
      name: itemToEdit?.name || "",
      address: itemToEdit?.address || "",
      user_name: itemToEdit?.user_name || "",
      password: itemToEdit?.password || "",
      admin_password: itemToEdit?.admin_password || "",
      mobile_no: itemToEdit?.mobile_no || "",
      fixed_line: itemToEdit?.fixed_line || "",
      web_site_url: itemToEdit?.web_site_url || "",
      trip_advisor_url: itemToEdit?.trip_advisor_url || "",
      google_map_url: itemToEdit?.google_map_url || "",
      facebook_url: itemToEdit?.facebook_url || "",
      instagram_url: itemToEdit?.instagram_url || "",
      twitter_url: itemToEdit?.twitter_url || "",
      language: itemToEdit?.language || restaurantLanguages[0].value,
      allow_payments: itemToEdit?.allow_payments === false ? false : true,
      one_order_per_table:
        itemToEdit?.one_order_per_table === false ? false : true,
      print_receipt: itemToEdit?.print_receipt || false,
      currency: itemToEdit?.currency || "LKR",
      printer_model: itemToEdit?.printer_model || "",
      app_fee_percentage: itemToEdit?.app_fee_percentage || 5,
      bank_account_details: itemToEdit?.bank_account_details || "",
      self_ordering: itemToEdit?.self_ordering || false,
      print_kitchen_note: itemToEdit?.print_kitchen_note || false,
      kitchen_printer_model: itemToEdit?.kitchen_printer_model || "",
      transaction_methods: itemToEdit?.transaction_methods || [],
    });
  };
  useEffect(() => {
    if (openRestaurantEditModal) {
      setInitialData();
    }
  }, [openRestaurantEditModal]); // eslint-disable-line

  const onClose = () => {
    setOpenRestaurantEditModal(false);
  };

  const setFile = (file, key) => {
    changeState(key, file);
  };

  const onSubmit = () => {
    const formData = new FormData();
    if (state.logo_image) {
      formData.append("file", state.logo_image);
    }
    if (!state.name) {
      return showAlert({
        head: "Please be careful!",
        body: "Please enter the name of your 'Restaurant'",
      });
    }
    formData.append("name", state.name);
    if (!state.address) {
      return showAlert({
        head: "Please be careful!",
        body: "Please enter the address of your 'Restaurant'",
      });
    }
    formData.append("address", state.address);

    if (!state.user_name) {
      return showAlert({
        head: "Please be careful!",
        body: "Please enter an User Name for your 'Restaurant'",
      });
    }
    formData.append("user_name", state.user_name);
    if (itemToEdit) {
      formData.append("password", state.password);
      formData.append("admin_password", state.admin_password);
    }
    formData.append("mobile_no", state.mobile_no);
    formData.append("fixed_line", state.fixed_line);
    formData.append("web_site_url", state.web_site_url);
    formData.append("trip_advisor_url", state.trip_advisor_url);
    formData.append("google_map_url", state.google_map_url);
    formData.append("facebook_url", state.facebook_url);
    formData.append("instagram_url", state.instagram_url);
    formData.append("twitter_url", state.twitter_url);
    formData.append("language", state.language);

    formData.append("allow_payments", state.allow_payments);
    formData.append("self_ordering", state.self_ordering);
    formData.append("one_order_per_table", state.one_order_per_table);
    formData.append("app_fee_percentage", state.app_fee_percentage);
    formData.append("bank_account_details", state.bank_account_details);

    if (itemToEdit) {
      formData.append("print_receipt", state.print_receipt);
      formData.append("currency", state.currency);
      if (state.print_receipt) {
        formData.append("printer_model", state.printer_model);
      }

      formData.append("print_kitchen_note", state.print_kitchen_note);
      if (state.print_kitchen_note) {
        formData.append("kitchen_printer_model", state.kitchen_printer_model);
      }
      formData.append("transaction_methods", state.transaction_methods);
    }

    if (itemToEdit) {
      AdminApiService()
        .updateRestaurantCall(formData, itemToEdit.id)
        .then((response) => {
          if (response.success) {
            AdminApiService().getAllRestaurantsCall();
            onClose();
          }
        });
    } else {
      AdminApiService()
        .createRestaurantCall(formData)
        .then((response) => {
          if (response.success) {
            AdminApiService().getAllRestaurantsCall();
            onClose();
          }
        });
    }
  };

  return (
    <Backdrop className={classes.backdrop} open={openRestaurantEditModal}>
      <Card className={classes.card}>
        <ModelHeader
          title={itemToEdit ? "Update Restaurant" : "Add New Restaurant"}
          onClose={onClose}
        />
        <div style={{ padding: "20px" }}>
          <Box height={10} />
          <div style={{ display: "flex" }}>
            <ImagePicker
              imageUrl={state.logo}
              setFile={(file) => {
                setFile(file, "logo_image");
              }}
              file={state.logo_image}
              title={"Restaurant Logo"}
            />
          </div>

          <Box height={30} />
          <TextField
            className={classes.text_field}
            label="Restaurant Name"
            value={state.name}
            onChange={(event) => {
              changeState("name", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <Box height={20} />
          <TextField
            className={classes.text_field}
            style={{ width: "45%", marginRight: "10%" }}
            label="Restaurant Code"
            value={state.code}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            disabled
          />
          <TextField
            type="text"
            className={classes.text_field}
            style={{ width: "45%" }}
            label="Restaurant Language"
            value={state.language}
            onChange={(event) => {
              changeState("language", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            select
          >
            {restaurantLanguages.map((lang, index) => (
              <MenuItem key={index} value={lang.value}>
                {lang.label}
              </MenuItem>
            ))}
          </TextField>
          <Box height={20} />
          {itemToEdit?.currencies && (
            <TextField
              type="text"
              className={classes.text_field}
              style={{ width: "45%", marginRight: "10%" }}
              label="Restaurant Currency"
              value={state.currency}
              onChange={(event) => {
                changeState("currency", event.target.value);
              }}
              InputLabelProps={{ shrink: true }}
              select
            >
              {itemToEdit.currencies.map((currency, index) => (
                <MenuItem key={index} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </TextField>
          )}
          <TextField
            className={classes.text_field}
            style={{ width: "45%" }}
            label="Restaurant User Name"
            value={state.user_name}
            onChange={(event) => {
              changeState("user_name", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />

          {itemToEdit && (
            <>
              <Box height={20} />
              <div>
                <TextField
                  type="password"
                  className={classes.text_field}
                  style={{ width: "45%", marginRight: "10%" }}
                  label="Restaurant Password"
                  value={state.password}
                  onChange={(event) => {
                    changeState("password", event.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  type="password"
                  className={classes.text_field}
                  style={{ width: "45%" }}
                  label="Restaurant Admin Password"
                  value={state.admin_password}
                  onChange={(event) => {
                    changeState("admin_password", event.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </>
          )}
          <Box height={30} />
          <span>Configurations</span>
          <Box height={30} />
          <FormControlLabel
            style={{ width: "100%" }}
            control={
              <Switch
                checked={state.self_ordering}
                onChange={(event) => {
                  changeState("self_ordering", event.target.checked);
                }}
              />
            }
            label="Self ordering"
          />
          <Box height={10} />
          <FormControlLabel
            style={{ width: "100%" }}
            control={
              <Switch
                checked={state.one_order_per_table}
                onChange={(event) => {
                  changeState("one_order_per_table", event.target.checked);
                }}
              />
            }
            label="One order per table"
          />
          <Box height={10} />
          <FormControlLabel
            style={{ width: "100%" }}
            control={
              <Switch
                checked={state.allow_payments}
                onChange={(event) => {
                  changeState("allow_payments", event.target.checked);
                }}
              />
            }
            label="Allow Payments"
          />
          {state.allow_payments && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <TextField
                className={classes.text_field}
                label="Bank account details"
                value={state.bank_account_details}
                onChange={(event) => {
                  changeState("bank_account_details", event.target.value);
                }}
                InputLabelProps={{ shrink: true }}
                style={{ width: "75%" }}
              />
              <TextField
                type={"number"}
                className={classes.text_field}
                style={{ width: "20%" }}
                label="Fee Percentage"
                value={state.app_fee_percentage}
                onChange={(event) => {
                  changeState("app_fee_percentage", event.target.value);
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </div>
          )}

          {itemToEdit?.printers && (
            <>
              <div className={classes.switch}>
                <FormControlLabel
                  style={{ width: "50%" }}
                  control={
                    <Switch
                      checked={state.print_receipt}
                      onChange={(event) => {
                        changeState("print_receipt", event.target.checked);
                      }}
                    />
                  }
                  label="Print Receipt"
                />
                {state.print_receipt && (
                  <TextField
                    className={classes.text_field}
                    style={{ width: "50%" }}
                    label="Printer Model"
                    value={state.printer_model}
                    onChange={(event) => {
                      changeState("printer_model", event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                    select
                  >
                    {itemToEdit.printers.map((printer, index) => (
                      <MenuItem key={index} value={printer}>
                        {printer}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </div>
              <div className={classes.switch}>
                <FormControlLabel
                  style={{ width: "50%" }}
                  control={
                    <Switch
                      checked={state.print_kitchen_note}
                      onChange={(event) => {
                        changeState("print_kitchen_note", event.target.checked);
                      }}
                    />
                  }
                  label="Print Kitchen Note"
                />
                {state.print_kitchen_note && (
                  <TextField
                    className={classes.text_field}
                    style={{ width: "50%" }}
                    label="Kitchen Printer Model"
                    value={state.kitchen_printer_model}
                    onChange={(event) => {
                      changeState("kitchen_printer_model", event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                    select
                  >
                    {itemToEdit.printers.map((printer, index) => (
                      <MenuItem key={index} value={printer}>
                        {printer}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </div>
            </>
          )}
          {itemToEdit && (
            <>
              <Box height={20} />
              <Autocomplete
                clearIcon={false}
                options={[]}
                freeSolo
                multiple
                renderTags={(value, props) =>
                  value.map((option, index) => (
                    <Chip label={option} {...props({ index })} />
                  ))
                }
                value={state.transaction_methods}
                onChange={(event, values) => {
                  changeState("transaction_methods", values);
                }}
                renderInput={(params) => (
                  <TextField
                    label="Transaction Methods"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    {...params}
                  />
                )}
              />
              <div className={classes.info_text}>
                Be careful when updating these transaction methods! This methods
                will appear on restaurant payment summary popup and the sales
                filters. Removing transaction methods will cause issues.
              </div>
            </>
          )}

          <Box height={30} />
          <span>Contact Details</span>
          <Box height={30} />
          <TextField
            className={classes.text_field}
            label="Restaurant Address"
            value={state.address}
            onChange={(event) => {
              changeState("address", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <Box height={20} />
          <TextField
            type="text"
            className={classes.text_field}
            style={{ width: "45%", marginRight: "10%" }}
            label="Restaurant Mobile No"
            value={state.mobile_no}
            onChange={(event) => {
              changeState("mobile_no", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            type="text"
            className={classes.text_field}
            style={{ width: "45%" }}
            label="Restaurant Fixed Line"
            value={state.fixed_line}
            onChange={(event) => {
              changeState("fixed_line", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <Box height={20} />
          <TextField
            className={classes.text_field}
            label="Restaurant Web Site Url"
            value={state.web_site_url}
            onChange={(event) => {
              changeState("web_site_url", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <Box height={20} />
          <TextField
            className={classes.text_field}
            label="Restaurant Facebook Url"
            value={state.facebook_url}
            onChange={(event) => {
              changeState("facebook_url", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <Box height={20} />
          <TextField
            className={classes.text_field}
            label="Restaurant Instagram Url"
            value={state.instagram_url}
            onChange={(event) => {
              changeState("instagram_url", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <Box height={20} />
          <TextField
            className={classes.text_field}
            label="Restaurant Twitter Url"
            value={state.twitter_url}
            onChange={(event) => {
              changeState("twitter_url", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <Box height={20} />
          <TextField
            className={classes.text_field}
            label="Trip Advisor Url"
            value={state.trip_advisor_url}
            onChange={(event) => {
              changeState("trip_advisor_url", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <Box height={20} />
          <TextField
            className={classes.text_field}
            label="Google Map Url"
            value={state.google_map_url}
            onChange={(event) => {
              changeState("google_map_url", event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <Box height={20} />
          <div className={classes.button_row}>
            <Button1 onClick={onSubmit} isSelected title="Submit" />
          </div>
        </div>
      </Card>
    </Backdrop>
  );
}
