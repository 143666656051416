export const background_color = "#ffffff";
export const background_color_2 = "#f4f5f8";

export const text_color_dark = "#515051";
export const text_color_light = "#ffffff";

export const ceypay_logo_blue_color = "#262161";

export const primary_color_1 = "#f9774d";
export const primary_color_2 = "#f7506c";

export const payment_color_1 = "#00a523";
export const payment_color_2 = "#c1d520";

export const print_color_1 = "#0267b2";
export const print_color_2 = "#1cabd1";

export const print_color_3 = "#444343";
export const print_color_4 = "#969696";

export const model_header_color = "#f03f37";
export const customer_model_header_color = "black";

export const lime_soap = "#7bed9f";
export const vanadyl_blue = "#0097e6";

export const primary_gradient = `linear-gradient(45deg, ${primary_color_1}, ${primary_color_2})`;
export const primary_gradient_reverse = `linear-gradient(45deg, ${primary_color_2}, ${primary_color_1})`;
export const payment_gradient = `linear-gradient(45deg, ${payment_color_2}, ${payment_color_1}, ${payment_color_2})`;
export const payment_gradient_reverse = `linear-gradient(45deg,${payment_color_1},  ${payment_color_2}, ${payment_color_1})`;

export const print_gradient = `linear-gradient(45deg, ${print_color_1}, ${print_color_2})`;
export const print_gradient_reverse = `linear-gradient(45deg, ${print_color_2}, ${print_color_1})`;

export const kitchen_print_gradient = `linear-gradient(45deg, ${print_color_3}, ${print_color_4})`;
export const kitchen_print_gradient_reverse = `linear-gradient(45deg, ${print_color_4}, ${print_color_3})`;

export const button_red = "#ed5e68";
export const button_green = "#5c9b1d";
