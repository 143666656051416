const { format } = require("date-fns");
const { padSpaces, wrapText } = require("../printer_helper");
let EscPosEncoder = require("esc-pos-encoder");

export const generateKitchenNote = ({ order, inches }) => {
  let encoder = new EscPosEncoder();

  let columnWidths;
  switch (inches) {
    case 2:
      columnWidths = [4, 28];
      break;
    case 3:
      columnWidths = [7, 37];
      break;
    default:
      columnWidths = [4, 28];
      break;
  }

  const totalWidth = columnWidths.reduce((a, b) => a + b);
  const orderId = `cey_${order.id}`;

  const commands = [];
  const topic = `\x1B\x21\x30${"Kitchen Note"}\n`;
  commands.push(new TextEncoder("utf-8").encode(topic));

  commands.push(
    encoder
      .initialize()
      .bold(false)
      .align("left")
      .line("-".repeat(totalWidth))
      .bold(true)
      .align("center")
      .line(format(new Date(), "dd/MM/yyyy hh:mm a"))
      .line(`Order Id: ${orderId}`)
      .line(`Table No: ${order.table.number}`)
      .bold(false)
      .line("-".repeat(totalWidth))
      .encode()
  );

  commands.push(
    encoder
      .initialize()
      .bold(true)
      .line(
        `${padSpaces("QTY", columnWidths[0], "center")}${padSpaces(
          "Item",
          columnWidths[1],
          "center"
        )}`
      )
      .bold(false)
      .encode()
  );

  order.order_items.forEach((item) => {
    const qty = item.quantity.toString();
    const name = item.item.name;
    const qtyWidth = columnWidths[0];
    const maxNameWidth = columnWidths[1];
    const remarkPad = 3;

    const wrappedName = wrapText(name, maxNameWidth);
    wrappedName.forEach((line, index) => {
      if (index === 0) {
        commands.push(
          encoder
            .initialize()
            .text(
              padSpaces(qty, qtyWidth, "center") +
                line.padEnd(maxNameWidth) +
                "\n"
            )
            .encode()
        );
      } else {
        commands.push(
          encoder
            .initialize()
            .text(" ".repeat(qtyWidth) + line.padEnd(maxNameWidth) + "\n")
            .encode()
        );
      }
    });

    const modifiers = [];
    item.modifiers?.forEach((modifier) => {
      modifier?.values?.forEach((value) => {
        modifiers.push(value);
      });
    });
    const remarks = [...(item.remark ? [item.remark] : []), ...modifiers];
    remarks.forEach((remark) => {
      const wrappedRemark = wrapText(remark, maxNameWidth - remarkPad);
      wrappedRemark.forEach((line, index) => {
        if (index === 0) {
          commands.push(
            encoder
              .initialize()
              .text(
                padSpaces(" ", qtyWidth, "center") +
                  padSpaces("-", remarkPad, "center") +
                  line.padEnd(maxNameWidth - remarkPad) +
                  "\n"
              )
              .encode()
          );
        } else {
          commands.push(
            encoder
              .initialize()
              .text(
                " ".repeat(qtyWidth + remarkPad) +
                  line.padEnd(maxNameWidth - remarkPad) +
                  "\n"
              )
              .encode()
          );
        }
      });
    });
    commands.push(encoder.initialize().newline().encode());
  });

  commands.push(
    encoder
      .initialize()
      .align("left")
      .bold(true)
      .align("center")
      .line("Powered by CeyPay")
      .newline()
      .newline()
      .newline()
      .encode()
  );

  return commands;
};
