import { TextField } from "@mui/material";
import React from "react";
import useStyles from "./remark.styles";

export default function Remark({ remark, setRemark }) {
  const classes = useStyles();
  return (
    <TextField
      className={classes.remarkField}
      label="Remark"
      name="remark"
      value={remark}
      onChange={(event) => {
        setRemark(event.target.value);
      }}
      InputLabelProps={{ shrink: true }}
    />
  );
}
