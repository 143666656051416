import { Backdrop, Box, Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./restaurant_select_item_popup_styles";
import ModelHeader from "../model_header/model_header";
import CustomButton from "../custom_button";
import { useStoreActions, useStoreState } from "easy-peasy";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import QuantityInput from "../quantity_input/quantiy_input";
import Modifiers from "../modifiers/modifiers";
import PriceTag from "../price_tag/price_tag";
import Remark from "../remark/remark";

export default function RestaurantSelectItemPopup() {
  const classes = useStyles();

  const [quantity, setQuantity] = useState(1);
  const [modifiers, setModifiers] = useState([]);
  const [remark, setRemark] = useState("");

  const restaurantSelectItemPopupOpen = useStoreState(
    (state) => state.restaurantSelectItemPopupOpen
  );
  const restaurantSelectedItem = useStoreState(
    (state) => state.restaurantSelectedItem
  );
  const selectedOrder = useStoreState((state) => state.selectedOrder);
  const { currency } = useStoreState((state) => state.restaurant);

  const setRestaurantSelectItemPopup = useStoreActions(
    (actions) => actions.setRestaurantSelectItemPopup
  );

  useEffect(() => {
    if (restaurantSelectItemPopupOpen) {
      setQuantity(1);
      setModifiers(
        restaurantSelectedItem?.modifiers.map((modifier) => ({
          ...modifier,
          selected: [],
        })) || []
      );
      setRemark("");
    }
  }, [restaurantSelectItemPopupOpen, restaurantSelectedItem]);

  const onSubmit = async () => {
    onClose();
    const data = {
      order_id: selectedOrder.id,
      item_id: restaurantSelectedItem.id,
      quantity: quantity,
      modifiers: [],
      remark,
    };
    modifiers.forEach((modifier) => {
      if (modifier.selected?.length > 0) {
        data.modifiers.push({
          name: modifier.name,
          type: modifier.type,
          values: modifier.selected,
        });
      }
    });
    await RestaurantApiService().addItemToOrderCall(data);
    RestaurantApiService().getRestaurantOrderByIdCall(selectedOrder.id, true);
  };

  const onClose = () => {
    setRestaurantSelectItemPopup(false);
  };

  return restaurantSelectedItem ? (
    <Backdrop className={classes.backdrop} open={restaurantSelectItemPopupOpen}>
      <Card className={classes.card}>
        <ModelHeader title="Add this item to order" onClose={onClose} />
        <div className={classes.modal_body}>
          <div className={classes.card_inner}>
            <div className={classes.row}>
              <div className={classes.full_width}>
                {restaurantSelectedItem.name}
              </div>
            </div>

            {restaurantSelectedItem.description && (
              <>
                <Box height={10} />
                <div className={classes.description_row}>
                  <span>-</span>
                  <div className={classes.description_text}>
                    {restaurantSelectedItem.description}
                  </div>
                </div>
              </>
            )}
            {modifiers.length > 0 && (
              <>
                <Box height={10} />
                <Modifiers modifiers={modifiers} setModifiers={setModifiers} />
              </>
            )}
          </div>
        </div>
        <div className={classes.bottom_section}>
          <div className={classes.qtyOuter}>
            <QuantityInput value={quantity} onChange={setQuantity} />
          </div>
          <Box height={10} />
          <PriceTag
            item={restaurantSelectedItem}
            currency={currency}
            qty={quantity}
          />
          <Box height={10} />
          <Remark remark={remark} setRemark={setRemark} />
          <Box height={10} />
          <div className={classes.button_row}>
            <CustomButton onClick={onSubmit}>Add Item to Order</CustomButton>
          </div>
        </div>
      </Card>
    </Backdrop>
  ) : (
    <div />
  );
}
