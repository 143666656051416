import { Backdrop, Box, Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./restaurant_order_item_edit_popup_styles";
import ModelHeader from "../model_header/model_header";
import CustomButton from "../custom_button";
import { useStoreActions, useStoreState } from "easy-peasy";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import Modifiers from "../modifiers/modifiers";
import Remark from "../remark/remark";

export default function RestaurantOrderItemEditPopup() {
  const classes = useStyles();

  const [state, setState] = useState({
    name: "",
    description: "",
    remark: "",
  });

  const [modifiers, setModifiers] = useState([]);

  const restaurantSelectedOrderItemToEdit = useStoreState(
    (state) => state.restaurantSelectedOrderItemToEdit
  );
  const setRestaurantSelectedOrderItemToEdit = useStoreActions(
    (actions) => actions.setRestaurantSelectedOrderItemToEdit
  );

  useEffect(() => {
    if (restaurantSelectedOrderItemToEdit) {
      const {
        item: { name, description, modifiers: itemModifiers },
        remark,
        modifiers: selectedModifiers,
      } = restaurantSelectedOrderItemToEdit;
      const itemModifiersParsed = JSON.parse(itemModifiers);
      setState({
        name,
        description,
        remark: remark || "",
      });
      const updatedModifiers = [];
      itemModifiersParsed.forEach((itemModifier) => {
        const selected =
          selectedModifiers.find((m) => m.name === itemModifier.name)?.values ||
          [];
        updatedModifiers.push({ ...itemModifier, selected });
      });
      setModifiers(updatedModifiers);
    }
  }, [restaurantSelectedOrderItemToEdit]);

  const onSubmit = async () => {
    onClose();
    const data = {
      item_id: restaurantSelectedOrderItemToEdit.id,
      modifiers: [],
      remark: state.remark,
    };
    modifiers.forEach((modifier) => {
      if (modifier.selected?.length > 0) {
        data.modifiers.push({
          name: modifier.name,
          type: modifier.type,
          values: modifier.selected,
        });
      }
    });
    await RestaurantApiService().updateOrderItemRemark(data);
    RestaurantApiService().getRestaurantOrderByIdCall(
      restaurantSelectedOrderItemToEdit.order_id,
      true
    );
  };

  const onClose = () => {
    setRestaurantSelectedOrderItemToEdit(null);
  };

  return restaurantSelectedOrderItemToEdit ? (
    <Backdrop
      className={classes.backdrop}
      open={!!restaurantSelectedOrderItemToEdit}
    >
      <Card className={classes.card}>
        <ModelHeader title="Update order item" onClose={onClose} />
        <div className={classes.modal_body}>
          <div className={classes.card_inner}>
            <div className={classes.row}>
              <div className={classes.full_width}>{state.name}</div>
            </div>

            {state.description && (
              <>
                <Box height={10} />
                <div className={classes.description_row}>
                  <span>-</span>
                  <div className={classes.description_text}>
                    {state.description}
                  </div>
                </div>
              </>
            )}
            {modifiers.length > 0 && (
              <>
                <Box height={10} />
                <Modifiers modifiers={modifiers} setModifiers={setModifiers} />
              </>
            )}
          </div>
        </div>
        <div className={classes.bottom_section}>
          <Box height={10} />
          <Remark
            remark={state.remark}
            setRemark={(updated) => setState({ ...state, remark: updated })}
          />
          <Box height={10} />
          <div className={classes.button_row}>
            <CustomButton onClick={onSubmit}>Update Item</CustomButton>
          </div>
        </div>
      </Card>
    </Backdrop>
  ) : (
    <div />
  );
}
