import { Backdrop, Box, Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./customer_select_item_popup_styles";
import { useStoreActions, useStoreState } from "easy-peasy";
import CustomerApiService from "../../api_service/customer_api_service";
import ModelHeader from "../model_header/model_header";
import CustomButton from "../custom_button";
import QuantityInput from "../quantity_input/quantiy_input";
import Modifiers from "../modifiers/modifiers";
import PriceTag from "../price_tag/price_tag";
import { getImageUrl } from "../../utils/helpers";
import { showAlert } from "../message_box/show_alert";
import Remark from "../remark/remark";

export default function CustomerSelectItemPopup({ refreshOrder }) {
  const item = useStoreState((state) => state.customerItemToAdd);
  const open = useStoreState((state) => state.customerOpenAddItemPopup);
  const setOpen = useStoreActions(
    (actions) => actions.setCustomerOpenAddItemPopup
  );
  const setItem = useStoreActions((actions) => actions.setCustomerItemToAdd);
  const classes = useStyles();

  const [quantity, setQuantity] = useState(1);
  const [modifiers, setModifiers] = useState([]);
  const [remark, setRemark] = useState("");

  const onClose = () => {
    setOpen(false);
    setItem(null);
  };

  useEffect(() => {
    if (open) {
      setQuantity(1);
      setModifiers(
        item?.item?.modifiers.map((modifier) => ({
          ...modifier,
          selected: [],
        })) || []
      );
      setRemark("");
    }
  }, [open, item]);

  const onSubmit = async () => {
    const data = {
      restaurant_code: item.restaurant_code,
      table_no: item.table_no,
      item_id: item.item.id,
      quantity: quantity ? Number(quantity) : 0,
      modifiers: [],
      remark,
    };
    modifiers.forEach((modifier) => {
      if (modifier.selected?.length > 0) {
        data.modifiers.push({
          name: modifier.name,
          type: modifier.type,
          values: modifier.selected,
        });
      }
    });
    const response = await CustomerApiService().addItemToOrderCall(data);
    if (response.success) {
      refreshOrder();
      onClose();
      showAlert({
        head: "Item added successfully! 🎉",
        body: `You've just ordered ${quantity} x ${item.item.name}. Feel free to keep exploring the digital menu for more delights, or review your order if you're all set`,
        actions: [
          {
            name: "Ok",
            action: null,
          },
        ],
        isCustomer: true,
      });
    } else {
      if (response.data && response.data.already_exists) {
        showAlert({ head: "Info", body: response.message, isCustomer: true });
      }
    }
  };

  return item ? (
    <Backdrop className={classes.backdrop} open={open}>
      <Card
        className={classes.card}
        style={{
          backgroundImage: `url(${getImageUrl(item.item.image)})`,
        }}
      >
        <ModelHeader title="Select QTY and order" customer onClose={onClose} />
        <div className={classes.modal_body}>
          <div className={classes.card_inner}>
            <div className={classes.row}>
              <div className={classes.item_name}>{item.item.name}</div>
            </div>

            {item.item.description && (
              <div className={classes.description_row}>
                <span>-</span>
                <div className={classes.description_text}>
                  {item.item.description}
                </div>
              </div>
            )}

            <Box height={10} />
            <Modifiers modifiers={modifiers} setModifiers={setModifiers} />
          </div>
        </div>
        <div className={classes.bottom_section}>
          <div className={classes.qtyOuter}>
            <QuantityInput value={quantity} onChange={setQuantity} />
          </div>
          <Box height={10} />
          <PriceTag currency={item.currency} item={item.item} qty={quantity} />
          <Box height={10} />
          <Remark remark={remark} setRemark={setRemark} />
          <Box height={10} />
          <div className={classes.button_row}>
            <CustomButton onClick={onSubmit}>Add to order</CustomButton>
          </div>
        </div>
      </Card>
    </Backdrop>
  ) : (
    <div />
  );
}
