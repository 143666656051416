import { Done, Remove, ChangeCircle } from "@mui/icons-material";
import { IconButton, InputBase } from "@mui/material";
import commaNumber from "comma-number";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useRef, useState } from "react";
import RestaurantApiService from "../../api_service/restaurant_api_service";
import { button_green, button_red } from "../../themes/colors";
import AdminPopup from "../app_bar/admin_popup";
import { showAlert } from "../message_box/show_alert";
import useStyles from "./bill_order_item_styles";

export default function BillOrderItem({ orderItem }) {
  const classes = useStyles();
  const [state, setState] = useState({
    admin_popup: false,
  });
  const isTablePadFullModeActive = useStoreState(
    (state) => state.isTablePadFullModeActive
  );
  const selectedOrder = useStoreState((state) => state.selectedOrder);
  const setRestaurantSelectedOrderItemToEdit = useStoreActions(
    (actions) => actions.setRestaurantSelectedOrderItemToEdit
  );
  const nameRef = useRef(null);
  const [nameWidth, setNameWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (nameRef.current) {
        setNameWidth(nameRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [nameRef.current, isTablePadFullModeActive]); //eslint-disable-line

  const onQuantityChange = (event) => {
    if (Number(event.target.value)) {
      RestaurantApiService().updateOrderItemQuantityCall(
        orderItem.order_id,
        orderItem.id,
        Number(event.target.value)
      );
    }
  };

  const onRemoveItem = async () => {
    await RestaurantApiService().removeItemFromOrderCall(
      orderItem.order_id,
      orderItem.id
    );
    RestaurantApiService().getRestaurantOrderByIdCall(selectedOrder.id, true);
  };

  const serveItem = () => {
    showAlert({
      head: "Are you sure?",
      body: `Tap on 'yes' if your're ready to serve ${orderItem.quantity} x ${orderItem.item.name}?`,
      actions: [
        {
          name: "No",
          color: button_red,
        },
        {
          name: "Yes",
          color: button_green,
          action: () => {
            RestaurantApiService().serveItemCall(orderItem.id, true);
          },
        },
      ],
      isCustomer: false,
    });
  };

  const revertServedItem = async () => {
    await RestaurantApiService().serveItemCall(orderItem.id, false);
  };

  const onRevert = () => {
    showAlert({
      head: "Are you sure?",
      body: `Tap on 'yes' if you you want to revert back served item  ${orderItem.quantity} x ${orderItem.item.name} ?`,
      actions: [
        {
          name: "No",
          color: button_red,
        },
        {
          name: "Yes",
          color: button_green,
          action: () => {
            setState({ ...state, admin_popup: true });
          },
        },
      ],
    });
  };

  const openOrderItemEditPopup = () => {
    setRestaurantSelectedOrderItemToEdit(orderItem);
  };

  const hasModifiers = orderItem.remark || orderItem?.modifiers?.length > 0;

  return (
    <>
      <div
        className={classes.row}
        style={{
          marginTop: isTablePadFullModeActive ? 15 : 0,
        }}
      >
        <AdminPopup
          open={state.admin_popup}
          setOpen={(val) => {
            setState({ ...state, admin_popup: val });
          }}
          customAction={revertServedItem}
        />
        {orderItem?.served ? (
          <IconButton className={classes.doneBtn}>
            <Done fontSize="small" />
          </IconButton>
        ) : (
          !isTablePadFullModeActive && (
            <IconButton onClick={onRemoveItem} className={classes.removeBtn}>
              <Remove fontSize="small" />
            </IconButton>
          )
        )}
        <InputBase
          id={orderItem.id.toString()}
          autoFocus={orderItem.newlyAdded}
          onFocusCapture={() => {
            document.getElementById(orderItem.id.toString()).select();
          }}
          type="text"
          value={orderItem.quantity}
          title="Quantity"
          onChange={onQuantityChange}
          classes={{
            input: classes.inputStyle,
          }}
          className={classes.qty_field}
          disabled
        />
        <div className={classes.x}>X</div>
        <div
          className={classes.nameWrapper}
          ref={nameRef}
          onClick={openOrderItemEditPopup}
        >
          <div className={classes.name} style={{ maxWidth: nameWidth - 45 }}>
            {orderItem.item.name}
          </div>
        </div>

        <div className={classes.amount}>
          {commaNumber((orderItem.unit_price * orderItem.quantity).toFixed(2))}
        </div>
        {!orderItem?.served && (
          <div className={classes.pendingOuter} onClick={serveItem}>
            <img
              height={20}
              src={"/images/pending.gif"}
              alt="order item status"
            />
          </div>
        )}

        {orderItem.served && (
          <IconButton onClick={onRevert} className={classes.changeCircle}>
            <ChangeCircle fontSize="medium" />
          </IconButton>
        )}
      </div>
      {hasModifiers && (
        <div className={classes.modifiers}>
          {orderItem.remark && <div>- {orderItem.remark}</div>}
          {orderItem?.modifiers?.map((modifier, index) => (
            <div key={`${index}-${modifier.name}`}>
              {modifier.values.map((modv, index) => (
                <div key={`${index}-${modv}`}>- {modv}</div>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
}
