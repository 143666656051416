const {
  default: LocalApiService,
} = require("../api_service/local_api_service");
const {
  default: RestaurantApiService,
} = require("../api_service/restaurant_api_service");
const { MTPPrint } = require("./mtp");

export const PrintTypes = {
  RECEIPT: "receipt",
  KITCHEN_NOTE: "kitchen_note",
};

export const printReceipt = async ({
  printerModel,
  orderOrId,
  directLocal,
  currency,
  type,
}) => {
  const response = await RestaurantApiService().getRestaurantOrderByIdCall(
    orderOrId
  );
  const order = response.data;
  switch (printerModel) {
    case "USB-EPOS80300":
      if (directLocal) {
        LocalApiService().receiptPrintCall(order.id);
      } else {
        RestaurantApiService().printRequestCall(order.id);
      }
      break;
    case "BT-MTP-3":
      MTPPrint({ order, currency, inches: 3, type });
      break;
    case "BT-MTP-2":
      MTPPrint({ order, currency, inches: 2, type });
      break;
    default:
      break;
  }
};
