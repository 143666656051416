const { format } = require("date-fns");
const commaNumber = require("comma-number");
const {
  getChargeName,
  padSpaces,
  divideLines,
  wrapText,
} = require("../printer_helper");
let EscPosEncoder = require("esc-pos-encoder");

export const generateReceipt = ({ order, currency, inches }) => {
  let encoder = new EscPosEncoder();

  let columnWidths;
  switch (inches) {
    case 2:
      columnWidths = [4, 18, 10];
      break;
    case 3:
      columnWidths = [7, 26, 11];
      break;
    default:
      columnWidths = [4, 18, 10];
      break;
  }

  const totalWidth = columnWidths.reduce((a, b) => a + b);
  const orderId = `cey_${order.id}`;
  const restaurantName = order.restaurant.name;

  const commands = [];
  commands.push(
    new TextEncoder("utf-8").encode(
      `\x1B\x21\x30${divideLines(restaurantName, 36)}\n`
    )
  );
  const wrappedAddress = wrapText(order.restaurant.address, totalWidth);
  wrappedAddress.forEach((addressLine) => {
    commands.push(
      encoder
        .initialize()
        .align("center")
        .size("normal")
        .bold(false)
        .line(addressLine)
        .encode()
    );
  });

  if (order.restaurant.mobile_no || order.restaurant.fixed_line) {
    commands.push(
      encoder
        .initialize()
        .align("center")
        .line(
          `${order.restaurant.mobile_no || " "} / ${
            order.restaurant.fixed_line || " "
          }`
        )
        .encode()
    );
  }

  commands.push(
    encoder
      .initialize()
      .align("left")
      .line("-".repeat(totalWidth))
      .align("center")
      .line("Order Receipt")
      .bold(true)
      .line(format(new Date(order.createdAt), "dd/MM/yyyy hh:mm a"))
      .bold(false)
      .line(`Order Id: ${orderId}`)
      .line(`Table No: ${order.table.number}`)
      .line(
        `Customer: ${order.customer_name || order.customer?.name || "Unknown"}`
      )
      .line(`Currency: ${currency ? `${currency}` : "LKR"}`)
      .align("left")
      .line("-".repeat(totalWidth))
      .encode()
  );

  commands.push(
    encoder
      .initialize()
      .bold(true)
      .line(
        `${padSpaces("QTY", columnWidths[0], "center")}${padSpaces(
          "Item",
          columnWidths[1],
          "left"
        )}${padSpaces(`Amount`, columnWidths[2], "right")}`
      )
      .bold(false)
      .encode()
  );

  order.order_items.forEach((item) => {
    const wrappedName = wrapText(item.item.name, columnWidths[1]);
    wrappedName.forEach((nameLine, index) => {
      if (index === 0) {
        commands.push(
          encoder
            .initialize()
            .line(
              `${padSpaces(
                item.quantity,
                columnWidths[0],
                "center"
              )}${padSpaces(
                nameLine,
                columnWidths[1],
                "left",
                false
              )}${padSpaces(
                commaNumber((item.quantity * item.unit_price).toFixed(2)),
                columnWidths[2],
                "right"
              )}`
            )
            .encode()
        );
      } else {
        commands.push(
          encoder
            .initialize()
            .line(
              `${padSpaces(" ", columnWidths[0], "center")}${padSpaces(
                nameLine,
                columnWidths[1],
                "left",
                false
              )}${padSpaces(" ", columnWidths[2], "right")}`
            )
            .encode()
        );
      }
    });
  });

  if (order.order_items.length > 0) {
    commands.push(
      encoder.initialize().align("left").line(" ".repeat(totalWidth)).encode()
    );
  }

  order.order_discounts.forEach((discount) => {
    commands.push(
      encoder
        .initialize()
        .line(
          `${padSpaces("", columnWidths[0], "center")}${padSpaces(
            getChargeName(discount),
            columnWidths[1],
            "left"
          )}${padSpaces(
            commaNumber((discount.calculated_value * -1).toFixed(2)),
            columnWidths[2],
            "right"
          )}`
        )
        .encode()
    );
  });

  if (order.order_discounts.length > 0) {
    commands.push(
      encoder.initialize().align("left").line(" ".repeat(totalWidth)).encode()
    );
  }

  order.order_charges.forEach((charge) => {
    commands.push(
      encoder
        .initialize()
        .line(
          `${padSpaces("", columnWidths[0], "center")}${padSpaces(
            getChargeName(charge),
            columnWidths[1],
            "left"
          )}${padSpaces(
            commaNumber(charge.calculated_value.toFixed(2)),
            columnWidths[2],
            "right"
          )}`
        )
        .encode()
    );
  });

  if (order.order_charges.length > 0) {
    commands.push(
      encoder.initialize().align("left").line(" ".repeat(totalWidth)).encode()
    );
  }

  commands.push(
    encoder
      .initialize()
      .bold(true)
      .text(padSpaces("", columnWidths[0], "center"))
      .text(padSpaces("Total Amount", columnWidths[1], "left"))
      .text(
        padSpaces(
          commaNumber(order.order_amount.toFixed(2)),
          columnWidths[2],
          "right"
        )
      )
      .line("")
      .align("left")
      .bold(false)
      .line("-".repeat(totalWidth))
      .align("center")
      .bold(true)
      .line("Thank you. Come Again!")
      .align("center")
      .line("Powered by CeyPay")
      .line("info@ceypayapp.com")
      .line("")
      .line("")
      .encode()
  );
  return commands;
};
