import { PrintTypes } from ".";
import { generateKitchenNote } from "./print_templates/kitchen_note";
import { generateReceipt } from "./print_templates/receipt";

const SERVICE = "000018f0-0000-1000-8000-00805f9b34fb";
const WRITE = "00002af1-0000-1000-8000-00805f9b34fb";

let savedDevice;

export const MTPPrint = async ({ order, currency, inches, type }) => {
  // inches - 2,3
  // type - PrintTypes

  let commands = [];
  switch (type) {
    case PrintTypes.RECEIPT:
      commands = generateReceipt({
        order,
        currency,
        inches,
      });
      break;
    case PrintTypes.KITCHEN_NOTE:
      commands = generateKitchenNote({
        order,
        inches,
      });
      break;
    default:
      break;
  }

  if (window.flutter_inappwebview?.callHandler) {
    window.flutter_inappwebview?.callHandler("printReceipt", commands);
    return;
  }

  try {
    if (!savedDevice) {
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ services: [SERVICE] }],
      });
      savedDevice = device;
    }

    const server = await savedDevice.gatt.connect();
    const service = await server.getPrimaryService(SERVICE);
    const channel = await service.getCharacteristic(WRITE);
    for (const command of commands) {
      await channel.writeValue(command);
    }
    await savedDevice.gatt.disconnect();
  } catch (error) {
    window.alert(`Error while printing: ${error.message}`);
  }
};
